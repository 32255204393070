import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { GifIcon, PlayIcon } from '@mybridge/icons';
import styles from './gifplayer.module.scss';
import { Box, Square } from '../layout';
import Image from 'next/image';
import { GIFPlayIcon } from '@mybridge/icons/DashboardNewICons';

const GifPlayer = ({ videoUrl, videoThumbnail, ...props }) => {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting){
          setPlaying(true);
        }else{
          setPlaying(false);
        }
      },{
        threshold:0.5,
      }
    )

    if(videoRef.current){
      observer.observe(videoRef.current);
    }
    return () => {
      if(videoRef.current){
        observer.unobserve(videoRef.current);
      }
    }
  },[])

  return (
    <Box
      ref={videoRef}
      pos="relative"
      minH="300px"
      w="100%"
      {...(props.wrapperProps ?? {})}
      className={styles.gifPlayer}
    >
      {playing ? (
        <Box onClick={() => setPlaying(false)} cursor="pointer">
          <ReactPlayer
            url={videoUrl}
            playing
            controls={false}
            loop
            width="100%"
            height="100%"
            onReady={() => {
              props?.onLoad?.();
              setPlaying(true);
            }}
            {...props}
            style={{ opacity: playing ? 1 : 0, ...(props.style ?? {}) }}
          />
        </Box>
      ) : (
        <Box className={styles.thumbnail} onClick={() => setPlaying(true)}>
          <ReactPlayer
            url={videoUrl}
            light={
              videoThumbnail ? (
                <Image
                  src={videoThumbnail}
                  alt="Thumbnail"
                  width={570}
                  height={570}
                  loading='lazy'
                />
              ) : (
                <Image
                  src='/images/gif_default_img.svg'
                  alt="Thumbnail"
                  width={570}
                  height={570}
                  loading='lazy'
                />
              )
            }
            // playIcon={<GIFPlayIcon />}
            playIcon={<Image
              src='/images/GIFIconImage.svg'
              alt="Thumbnail"
              width={40}
              height={40}
              loading='lazy'
              className={styles.IconButton}
            />}
            width="100%"
            height="100%"
          />
        </Box>
      )}
    </Box>
  );
};

export default GifPlayer;
