export const TeamsChatIcon = (props) => {
  return (
<svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dii_19912_10999)">
<circle cx="27.5" cy="27.5" r="17.5" fill="#D9D9D9"/>
</g>
<path d="M35.6561 22.6858C36.9501 22.6858 37.9991 21.6369 37.9991 20.3429C37.9991 19.049 36.9501 18 35.6561 18C34.3622 18 33.3132 19.049 33.3132 20.3429C33.3132 21.6369 34.3622 22.6858 35.6561 22.6858Z" fill="#575757"/>
<path d="M20.3429 38.0003C21.6369 38.0003 22.6858 36.9513 22.6858 35.6574C22.6858 34.3634 21.6369 33.3145 20.3429 33.3145C19.049 33.3145 18 34.3634 18 35.6574C18 36.9513 19.049 38.0003 20.3429 38.0003Z" fill="#575757"/>
<path d="M20.3429 22.6858C21.6369 22.6858 22.6858 21.6369 22.6858 20.3429C22.6858 19.049 21.6369 18 20.3429 18C19.049 18 18 19.049 18 20.3429C18 21.6369 19.049 22.6858 20.3429 22.6858Z" fill="#575757"/>
<path d="M35.6561 38.0003C36.9501 38.0003 37.9991 36.9513 37.9991 35.6574C37.9991 34.3634 36.9501 33.3145 35.6561 33.3145C34.3622 33.3145 33.3132 34.3634 33.3132 35.6574C33.3132 36.9513 34.3622 38.0003 35.6561 38.0003Z" fill="#575757"/>
<path d="M25.9283 25.9297C25.3898 26.4682 24.578 26.5842 23.932 26.2694L22.2007 28.0006L24.6857 30.4856L24.2715 30.8998C24.0396 31.1318 24.0396 31.4961 24.2715 31.7282C24.5036 31.9601 24.8679 31.9601 25.1 31.7282L25.5141 31.314L27.9991 33.799L29.7303 32.0677C29.4155 31.4217 29.5315 30.6099 30.07 30.0715C30.6085 29.533 31.4202 29.417 32.0663 29.7318L33.7976 28.0006L31.3125 25.5155L31.7267 25.1014C31.9587 24.8694 31.9587 24.505 31.7267 24.273C31.4947 24.0411 31.1303 24.0411 30.8983 24.273L30.4842 24.6872L27.9991 22.2021L26.2679 23.9334C26.5827 24.5795 26.4667 25.3912 25.9283 25.9297Z" fill="#575757"/>
<path d="M34.8274 29.0285L34.6261 28.8272L32.1411 31.3123L31.727 30.8981C31.495 30.6661 31.1306 30.6661 30.8986 30.8981C30.6666 31.1301 30.6666 31.4945 30.8986 31.7265L31.3128 32.1406L28.8277 34.6257L29.029 34.8269C30.1721 35.9701 32.0276 35.9701 33.1708 34.8269L34.8274 33.1702C35.9706 32.0271 35.9706 30.1716 34.8274 29.0285ZM21.1713 26.9693L21.3726 27.1705L23.8576 24.6855L24.2717 25.0997C24.5038 25.3316 24.8681 25.3316 25.1002 25.0997C25.3321 24.8676 25.3321 24.5033 25.1002 24.2713L24.686 23.8571L27.171 21.3721L26.9698 21.1708C25.8266 20.0277 23.9711 20.0277 22.828 21.1708L21.1713 22.8275C20.0282 23.9706 20.0282 25.8261 21.1713 26.9693ZM30.559 23.1034C31.2051 22.7885 32.0168 22.9046 32.5553 23.443C33.0937 23.9814 33.2097 24.7932 32.8948 25.4392C33.0057 25.5501 34.7112 27.2555 34.6261 27.1705L34.8274 26.9693C35.9706 25.8261 35.9706 23.9706 34.8274 22.8275L33.1707 21.1708C32.0276 20.0277 30.1721 20.0277 29.0289 21.1708L28.8277 21.3721C28.9224 21.4667 30.6668 23.2112 30.559 23.1034ZM26.9698 34.8269L27.171 34.6257C27.0764 34.5311 25.3319 32.7865 25.4397 32.8944C24.7937 33.2092 23.9819 33.0932 23.4434 32.5548C22.905 32.0163 22.789 31.2046 23.1039 30.5585C22.993 30.4476 21.2875 28.7422 21.3726 28.8272L21.1713 29.0285C20.0282 30.1716 20.0282 32.0271 21.1713 33.1703L22.828 34.827C23.9711 35.9701 25.8266 35.9701 26.9698 34.8269Z" fill="#575757"/>
<defs>
<filter id="filter0_dii_19912_10999" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19912_10999"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19912_10999" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-5"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_19912_10999"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_19912_10999" result="effect3_innerShadow_19912_10999"/>
</filter>
</defs>
</svg>)
}
