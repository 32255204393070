import { forwardRef } from 'react';
export const MyArticlesIcon = forwardRef(({ ...props }, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 22H8.33477V13.1094H1V22ZM2.83477 15.3333H6.5V19.776H2.83477V15.3333ZM10.1652 20.8906H23V18.6667H10.1652V20.8906ZM1 10.8906H8.33477V2H1V10.8906ZM2.83477 4.22396H6.5V8.66667H2.83477V4.22396ZM10.1652 3.10938V5.33333H23V3.10938H10.1652ZM10.1652 16.4427H23V14.2188H10.1652V16.4427ZM10.1652 9.77604H23V7.55208H10.1652V9.77604Z"
        fill={props?.color ?? '#5B5B5B'}
      />
    </svg>
  );
});

export const MyArticlesIconColored = forwardRef(({ ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16"       {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_18582_6427)">
<path d="M15.3335 -0.324219H10.0002C9.63197 -0.324219 9.3335 -0.025742 9.3335 0.342448V9.67578C9.3335 10.044 9.63197 10.3424 10.0002 10.3424H15.3335C15.7017 10.3424 16.0002 10.044 16.0002 9.67578V0.342448C16.0002 -0.025742 15.7017 -0.324219 15.3335 -0.324219Z" fill="#0D7FFE"/>
<path d="M15.3335 11.6758H10.0002C9.63197 11.6758 9.3335 11.9743 9.3335 12.3424V15.0091C9.3335 15.3773 9.63197 15.6758 10.0002 15.6758H15.3335C15.7017 15.6758 16.0002 15.3773 16.0002 15.0091V12.3424C16.0002 11.9743 15.7017 11.6758 15.3335 11.6758Z" fill="#68A4D1"/>
<path d="M7.33333 -0.324219H0.666667C0.298477 -0.324219 0 -0.0257419 0 0.342448V5.00911C0 5.3773 0.298477 5.67578 0.666667 5.67578H7.33333C7.70152 5.67578 8 5.3773 8 5.00911V0.342448C8 -0.0257419 7.70152 -0.324219 7.33333 -0.324219Z" fill="#68A4D1"/>
<path d="M7.33333 7.00879H0.666667C0.298477 7.00879 0 7.30727 0 7.67546V15.0088C0 15.377 0.298477 15.6755 0.666667 15.6755H7.33333C7.70152 15.6755 8 15.377 8 15.0088V7.67546C8 7.30727 7.70152 7.00879 7.33333 7.00879Z" fill="#0D7FFE"/>
</g>
<defs>
<clipPath id="clip0_18582_6427">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

  );
});
