import { forwardRef } from 'react';
export const ShareIcon = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17.0074 5.44556C16.9045 5.57838 16.8115 5.72448 16.6953 5.84402C15.2409 7.36147 13.7799 8.8756 12.3256 10.3931C12.1695 10.5558 12.0002 10.662 11.7677 10.6155C11.4954 10.5591 11.3394 10.3466 11.3394 10.0278C11.3361 9.35375 11.3394 8.67637 11.3394 8.00232C11.3394 7.94255 11.3394 7.88278 11.3394 7.82966C11.3195 7.81305 11.3095 7.80309 11.3029 7.80309C9.50649 7.68688 7.87614 8.09529 6.52471 9.35043C6.01668 9.82194 5.61158 10.3698 5.29282 10.9841C5.13344 11.2929 4.84123 11.4025 4.56232 11.2697C4.38301 11.1833 4.27012 11.0405 4.26679 10.8413C4.24687 9.79537 4.22031 8.7461 4.56232 7.73668C5.43892 5.1268 7.22201 3.51969 9.92155 2.95521C10.3233 2.8722 10.745 2.86888 11.1568 2.82904C11.2099 2.8224 11.263 2.8224 11.3361 2.81907C11.3361 2.74935 11.3361 2.6929 11.3361 2.63313C11.3361 1.99228 11.346 1.34811 11.3327 0.707259C11.3261 0.385174 11.4224 0.13946 11.7279 0C11.8175 0 11.9039 0 11.9935 0C12.1197 0.0962935 12.2625 0.175985 12.3687 0.28888C13.8131 1.78309 15.2575 3.28062 16.6953 4.78147C16.8115 4.90101 16.9012 5.04711 17.0041 5.17992C17.0074 5.26958 17.0074 5.35591 17.0074 5.44556Z"
          fill={props?.color ?? '#5B5B5B'}
        />
        <path
          d="M0.00664093 4.68186C0.092973 4.43946 0.1461 4.17715 0.265637 3.95467C0.664093 3.23413 1.29166 2.85228 2.11514 2.83236C2.81575 2.81575 3.51969 2.82572 4.22363 2.82904C4.66525 2.83236 4.96742 3.13784 4.96077 3.5529C4.95413 3.95799 4.64201 4.24355 4.20703 4.24355C3.5363 4.24355 2.86888 4.24355 2.19815 4.24355C1.71004 4.24355 1.42448 4.52579 1.42448 5.01722C1.42448 8.28124 1.42448 11.5453 1.42448 14.8093C1.42448 15.2907 1.70672 15.573 2.18487 15.573C6.39522 15.573 10.6056 15.573 14.8159 15.573C15.304 15.573 15.5829 15.2907 15.5829 14.796C15.5829 12.9598 15.5829 11.1236 15.5829 9.28734C15.5829 9.03831 15.646 8.81584 15.8486 8.65313C16.2138 8.35429 16.7551 8.48711 16.9443 8.92209C16.9643 8.96858 16.9842 9.01174 17.0041 9.05823C17.0041 11.0837 17.0041 13.1092 17.0041 15.1347C16.9941 15.1646 16.9809 15.1944 16.9742 15.2277C16.7982 16.091 16.2836 16.6521 15.4468 16.9178C15.3472 16.9477 15.2443 16.9709 15.1446 16.9941C10.7185 16.9941 6.28896 16.9941 1.86278 16.9941C1.83622 16.9842 1.81297 16.9709 1.78641 16.9643C1.13228 16.848 0.624247 16.5094 0.298842 15.9316C0.162703 15.6859 0.0962935 15.4003 0 15.1314C0.00664093 11.6548 0.00664093 8.16834 0.00664093 4.68186Z"
          fill={props?.color ?? '#5B5B5B'}
        />
      </svg>
    </>
  );
});
